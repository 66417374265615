@import '~antd/dist/antd.css';
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-row {
  margin-bottom: 8px;
}
.ant-radio-wrapper,
.ant-checkbox-wrapper {
  display: block;
}
.ant-menu {
  border-right-color: transparent;
}
.ant-layout-sider {
  background: white;
  border-right: 1px solid #e8e8e8;
}

.inline-block {
  display: inline-block;
}